import { CryptoFrontendStatus, SettlementStatus } from '@/api/queryHooks/useTransactionsController';

export interface ErrorObject {
  code: string;
  fields: [];
  message: string;
  path: string;
  status: string;
  timestamp: string;
}

export interface TxnWSMessage {
  actualAmount: null | string; // FIXME if needed
  redirectTo: null | string; // FIXME if needed
  replacedBy: string | null;
  replacementReason: 'NONE' | 'UNDER_PAID'; // FIXME if needed
  txHash: string | null;
  txId: string;
  txStatus: CryptoFrontendStatus;
}

export interface IList<T extends Record<string, any>> {
  totalElements?: number;
  totalPages?: number;
  sort?: ISort;
  first?: boolean;
  last?: boolean;
  number?: number;
  numberOfElements?: number;
  pageable?: IPageable;
  size?: number;
  content?: T[];
  empty?: boolean;
}

export interface IPageable {
  pageNumber?: number;
  sort?: ISort;
  paged?: boolean;
  pageSize?: number;
  unpaged?: boolean;
  offset?: number;
}

export interface ISort {
  unsorted?: boolean;
  sorted?: boolean;
  empty?: boolean;
}

// NOTE search and filters are not implemented on BE
export interface SearchPaginationFilterParams {
  page?: string | number;
  size?: string | number;
  sortField?: string;
  isDesc?: boolean;
}

export interface BuyCryptoTxnResponse {
  cryptoCurrency?: CryptoCurrency;
  blockchainTxHash?: string;
  transferAddress?: string;
  cryptoNetwork?: CryptoCurrency;
  created?: string;
  cryptoAmount?: number;
  updated?: string;
  relayPayUserId?: string;
  paymentType?: PaymentType;
  fiatAmount?: number;
  fiatCurrency?: FiatCurrency;
  depositPayId?: string;
  depositAccountNumber?: string;
  depositBsb?: string;
  frontendStatus?: CryptoFrontendStatus;
  sendingNetworkFee?: number;
  creditCardFee?: number;
  creditCardTotal?: number;
  paymentMethodFee?: number;
  id?: string;
  blockChainExplorer?: string | null;
}

export interface MerchantPayment {
  merchantId?: string;
  created?: string;
  cryptoCurrency?: CryptoCurrency;
  transactionId?: string;
  orderId?: string;
  payerEmail?: string;
  fiatAmount?: number;
  fiatCurrency?: FiatCurrency;
  paymentStatus?: CryptoFrontendStatus;
  settlementStatus?: SettlementStatus;
  blockchainTxHash?: string;
  blockChainExplorer?: string | null;
}

export enum AgeGroup {
  NOT_ALLOWED = 'NOT_ALLOWED',
  ALLOWED = 'ALLOWED',
  ALLOWED_WITH_WARNING = 'ALLOWED_WITH_WARNING',
}

export enum OnfidoStatus {
  EMPTY = 'EMPTY',
  PENDING = 'PENDING',
  SUBMITTED = 'SUBMITTED',
  WITHDRAWN = 'WITHDRAWN',
  CLEAR = 'CLEAR',
  CONSIDER = 'CONSIDER',
  BIOMETRIC_REQUIRED = 'BIOMETRIC_REQUIRED',
  RETRY_ATTEMPTS_EXCEEDED = 'RETRY_ATTEMPTS_EXCEEDED',
  EXTRA_MANUAL_CHECK_REQUIRED = 'EXTRA_MANUAL_CHECK_REQUIRED',
  POSSIBLE_FRAUD_REGISTRY = 'POSSIBLE_FRAUD_REGISTRY',
  SELL_ONLY = 'SELL_ONLY',
  UNDER_AGE = 'UNDER_AGE',
  CONTACT_SUPPORT = 'CONTACT_SUPPORT',
  SELL_ONLY_CONTACT_SUPPORT = 'SELL_ONLY_CONTACT_SUPPORT',
}

export enum KYBStatus {
  EMPTY = 'EMPTY',
  PENDING = 'PENDING',
  WITHDRAWN = 'WITHDRAWN',
  CLEAR = 'CLEAR',
  CONSIDER = 'CONSIDER',
  BIOMETRIC_REQUIRED = 'BIOMETRIC_REQUIRED',
  RETRY_ATTEMPTS_EXCEEDED = 'RETRY_ATTEMPTS_EXCEEDED',
  EXTRA_MANUAL_CHECK_REQUIRED = 'EXTRA_MANUAL_CHECK_REQUIRED',
  POSSIBLE_FRAUD_REGISTRY = 'POSSIBLE_FRAUD_REGISTRY',
  UNDER_AGE = 'UNDER_AGE',
  CONTACT_SUPPORT = 'CONTACT_SUPPORT',
  SELL_ONLY = 'SELL_ONLY',
}

export enum OnfidoStep {
  NEW = 'NEW',
  STARTED = 'STARTED',
  PARTIAL_DOCUMENT_UPLOAD = 'PARTIAL_DOCUMENT_UPLOAD',
  DOCUMENT_UPLOADED = 'DOCUMENT_UPLOADED',
  PHOTO_UPLOADED = 'PHOTO_UPLOADED',
  SUBMITTED = 'SUBMITTED',
}

export type AccountPurpose = 'INVESTMENT' | 'TRADING' | 'PAY' | 'INTERNATIONAL' | 'OTHER';
export type EntityType =
  | 'PRIVATE'
  | 'TRADER'
  | 'PARTNERSHIP'
  | 'UNREGULATED'
  | 'ASSOCIATION'
  | 'PUBLIC'
  | 'TRUST_REGISTERED'
  | 'TRUST_UNREGISTERED'
  | 'TRUST_REGULATED'
  | 'TRUST_GOVERNMENT'
  | 'TRUST_FAMILY'
  | 'TRUST_CHARITABLE'
  | 'TRUST_TESTAMENTARY'
  | 'TRUST_UNIT'
  | 'TRUST_OTHER';

export enum Purpose {
  BUY = 'BUY',
  SELL = 'SELL',
}
export enum DocumentType {
  IDENTITY_VERIFICATION = 'IDENTITY_VERIFICATION',
  COMPANY_EXTRACT = 'COMPANY_EXTRACT',
  BANK_STATEMENT = 'BANK_STATEMENT',
  COMPANY_LOGO = 'COMPANY_LOGO',
}

export enum CryptoNetwork {
  ETHEREUM = 'ETHEREUM',
  BITCOIN = 'BITCOIN',
}

export interface SellCryptoTxResponse {
  id?: string;
  depositAddress?: string;
  depositQrCode?: string;
  frontendStatus?: CryptoFrontendStatus;
  fiatAmount?: number;
  cryptoAmount?: number;
  fiatCurrency?: FiatCurrency;
  cryptoCurrency?: CryptoCurrency;
  exchangeType?: SellExchangeType;
  cryptoNetwork?: CryptoCurrency;

  systemDepositAddress?: string;
  blockChainExplorer?: string | null;
  blockchainTxHash?: string;

  payoutType?: PaymentType;
  hasMemo?: boolean;
  depositMemo?: string | null;
}

export enum SellExchangeType {
  IndependentReserve = 'IndependentReserve',
  BtcMarkets = 'BtcMarkets',
  Stablehouse = 'Stablehouse',
  KrakenExchange = 'KrakenExchange',
  BinanceInstant = 'BinanceInstant',
  GoerliTestNet = 'GoerliTestNet',
  SAUDReserve = 'sAUDReserve',
  SUSDReserve = 'sUSDReserve',
  RinkebyTestNet = 'RinkebyTestNet',
  RopstenTestNet = 'RopstenTestNet',
  KovanTestNet = 'KovanTestNet',
}

export enum PaymentType {
  NPP_BANK_TRANSFER = 'NPP_BANK_TRANSFER',
  PAYID = 'PAYID',
  BPAY = 'BPAY',
  INTERNATIONAL_BSB = 'INTERNATIONAL_BSB',
  INTERNATIONAL_IBAN = 'INTERNATIONAL_IBAN',
  INTERNATIONAL_ABA = 'INTERNATIONAL_ABA',
  INTERNATIONAL_ACC_NO = 'INTERNATIONAL_ACC_NO',
  INTERNATIONAL_SORT_CODE = 'INTERNATIONAL_SORT_CODE',
  INTERNATIONAL_IFSC = 'INTERNATIONAL_IFSC',
  INTERNATIONAL_CNAPS = 'INTERNATIONAL_CNAPS',
  INTERNATIONAL_PAYID = 'INTERNATIONAL_PAYID',
  INTERNATIONAL_CLABE = 'INTERNATIONAL_CLABE',
  INTERNATIONAL_PH_CASH = 'INTERNATIONAL_PH_CASH',
  INTERNATIONAL_RIPPLE = 'INTERNATIONAL_RIPPLE',
  INTERNATIONAL_BIC = 'INTERNATIONAL_BIC',
  INTERNATIONAL_SWIFT = 'INTERNATIONAL_SWIFT',
  COMMERCE = 'COMMERCE',
  GIFT_CARD = 'GIFT_CARD',
  PAY_ID_IN = 'PAY_ID_IN',
  POLI = 'POLI',
  CARD_PAYMENT = 'CARD_PAYMENT',
  CASH_IN = 'CASH_IN',
  BANK_TRANSFER = 'BANK_TRANSFER',
}

export enum CryptoCurrency {
  ETH = 'ETH',
  BTC = 'BTC',
  SAUD = 'sAUD',
  SUSD = 'sUSD',
  USDC = 'USDC',
  USDT = 'USDT',
  DAI = 'DAI',
  SEUR = 'sEUR',
  BUSD = 'BUSD',
  GUSD = 'GUSD',
  PAX = 'PAX',
  LINK = 'LINK',
  COMP = 'COMP',
  SNX = 'SNX',
  AAVE = 'AAVE',
  YFI = 'YFI',
  WBTC = 'WBTC',
  RENBTC = 'RENBTC',
  BNB = 'BNB',
  TUSD = 'TUSD',
  POL = 'POL',
  ARB = 'ARB',
  OP = 'OP',
  UNI = 'UNI',
  SHIB = 'SHIB',
  APE = 'APE',
  SOL = 'SOL',
  AVAX = 'AVAX',
  TRX = 'TRX',
  UNISWAP = 'UNISWAP',
  DOGE = 'DOGE',
  LTC = 'LTC',
  XRP = 'XRP',
  XLM = 'XLM',
}

export enum FiatCurrency {
  USD = 'USD',
  GBP = 'GBP',
  EUR = 'EUR',
  AUD = 'AUD',
  AED = 'AED',
  CAD = 'CAD',
  CHF = 'CHF',
  CNY = 'CNY',
  HKD = 'HKD',
  INR = 'INR',
  JPY = 'JPY',
  NZD = 'NZD',
  PHP = 'PHP',
  SEK = 'SEK',
  SGD = 'SGD',
  THB = 'THB',
  TRY = 'TRY',
  ZAR = 'ZAR',
  BGN = 'BGN',
  BRL = 'BRL',
  COP = 'COP',
  CZK = 'CZK',
  DKK = 'DKK',
  DOP = 'DOP',
  EGP = 'EGP',
  HRK = 'HRK',
  IDR = 'IDR',
  ILS = 'ILS',
  JOD = 'JOD',
  KES = 'KES',
  KRW = 'KRW',
  KWD = 'KWD',
  LKR = 'LKR',
  MAD = 'MAD',
  MXN = 'MXN',
  MYR = 'MYR',
  NGN = 'NGN',
  NOK = 'NOK',
  OMR = 'OMR',
  PEN = 'PEN',
  PKR = 'PKR',
  PLN = 'PLN',
  RON = 'RON',
  RUB = 'RUB',
  TWD = 'TWD',
  VND = 'VND',
}
